<script>
import {Bar} from 'vue-chartjs';
import {Line} from 'vue-chartjs';

export default {
  data() {
    return {
      month_data: [],
      avg: "",
    }
  },
  created() {
    this.getActStatistics();
  },
	extends: Bar, Line,
  methods: {
    getActStatistics() {
      this.$store.dispatch("getActStatistics").then((response) => {
        console.log(response.data);
        this.month_data = response.data.month_data;
        this.avg = response.data.avg;

        this.gradient = this.$refs.canvas
            .getContext('2d')
            .createLinearGradient(0, 0, 0, 450);
        this.gradient2 = this.$refs.canvas
            .getContext('2d')
            .createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, 'rgba(0, 0,0, 0.5)');
        this.gradient.addColorStop(0.5, 'rgba(0, 0, 0, 0.25)');
        this.gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

        this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)');
        this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
        this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 1)');

        this.renderChart(
            {
              labels: [
                'Январь',
                'Февраль',
                'Март',
                'Апрель',
                'Май',
                'Июнь',
                'Июль',
                'Август',
                'Сентябрь',
                'Октябрь',
                'Ноябрь',
                'Декабрь',
              ],
              datasets: [
                {
                  label: 'Среднее кол-во актов в месяц',
                  type: "line",
                  borderColor: "#86f879",
                  data: [this.avg, this.avg, this.avg, this.avg, this.avg, this.avg, this.avg, this.avg, this.avg, this.avg, this.avg, this.avg],
                },
                {
                  label: 'Кол-во согласованных актов по месяцам',
                  // backgroundColor: "#f87979",
                  type: "bar",
                  backgroundColor: ["#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979", "#f87979"],
                  data: this.month_data,
                }
              ],
            },
            {
              title: {
                display: true,
                text: 'Согласованные акты за 2025 год',
                fontSize: "20",
              },
              legend: {
                position: 'bottom',
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
            }
        );
      }).catch((error) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: error.response.data.detail,
        })
      });
  },
}
}
</script>
